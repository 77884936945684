import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ProjectHeadline from "../../components/project-details/ProjectHeadline";
import dataHelpers from "../../helpers/dataHelpers";
import BannerWithImage from "./../../components/common/Banners/BannerWithImage";
import Spacer from "./../../components/common/Spacer";
import ProjectAbout from "../../components/project-details/ProjectAbout";
import MediaSection from "../../components/project-details/MediaSection";
import ProjectNavigation from "../../components/project-details/ProjectNavigation";

import "./style.scss";

function ProjectDetail(props) {
  const [projectDetail, setProjectDetail] = useState({});
  const [prevProjectUrl, setPrevProjectUrl] = useState(null);
  const [nextProjectUrl, setNextProjectUrl] = useState(null);

  let { slug } = useParams();

  const getPrevProjectUrl = (data, index) => {
    if (index === 0) setPrevProjectUrl(data[data.length - 1].url);
    else setPrevProjectUrl(data[index - 1].url);
  };

  const getNextProjectUrl = (data, index) => {
    if (index === data.length - 1) setNextProjectUrl(data[0].url);
    else setNextProjectUrl(data[index + 1].url);
  };

  useEffect(() => {
    const index = dataHelpers.projects.findIndex((p) => p.url.includes(slug));
    const currentProject = dataHelpers.projects[index];

    if (currentProject) setProjectDetail(currentProject);
    getNextProjectUrl(dataHelpers.projects, index);
    getPrevProjectUrl(dataHelpers.projects, index);
  }, [slug]);

  return (
    <section className="project-details-page">
      <BannerWithImage imagePath={`/images/projects/banners/${projectDetail?.bannerImage}`} />

      <Spacer />

      <div className="project-details-inner">
        <ProjectHeadline title={projectDetail?.name} subTitle={projectDetail?.shortDescription} />

        <div className="row align-items-stretch">
          <div className="col-md-6">
            <ProjectAbout description={projectDetail?.description} />
          </div>
          <div className="col-md-6">
            <MediaSection medias={projectDetail?.media} />
          </div>
        </div>
        <Spacer />
        <ProjectNavigation prevProjectUrl={prevProjectUrl} nextProjectUrl={nextProjectUrl} />
      </div>
    </section>
  );
}

export default ProjectDetail;
