import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

function CaseStudies({ data, isGreyBg }) {
  return (
    <section className={`case-studies ${isGreyBg ? "grey" : ""}`}>
      <div className="container">
        <div className="case-studies-inner">
          <div className="row gx-5 gy-5">
            {data.map((item, index) => (
              <div className="col-12 col-sm-6 col-md-6" key={index}>
                <Link to={item.url} className="item">
                  <img src={`/images/projects/covers/${item.image}`} className="w-100" alt={item.name} />
                  <h2 className="label">{item.name}</h2>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaseStudies;
