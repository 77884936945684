import React, { useEffect, useState } from "react";
import dataHelpers from "../../helpers/dataHelpers";
import { useParams } from "react-router-dom";
import BannerWithVideo from "../../components/common/Banners/BannerWithVideo";
import "./style.scss";
import MediaSection from "../../components/common/CommsTools/MediaSection";
import PageNavigation from "../../components/common/CommsTools/PageNavigation";
import Properties from "../../components/common/CommsTools/Properties";

function CommsToolDetail() {
  const [pageDetail, setPageDetail] = useState({});
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [nextPageUrl, setNextPageUrl] = useState(null);

  let { slug } = useParams();

  const getPrevPageUrl = (data, index) => {
    if (index === 0) setPrevPageUrl(data[data.length - 1].url);
    else setPrevPageUrl(data[index - 1].url);
  };

  const getNextPageUrl = (data, index) => {
    if (index === data.length - 1) setNextPageUrl(data[0].url);
    else setNextPageUrl(data[index + 1].url);
  };

  useEffect(() => {
    const commsTools = dataHelpers.commsTools;

    const index = commsTools.findIndex((p) => p.url.includes(slug));
    const currentPage = commsTools[index];

    setPageDetail(currentPage);

    getNextPageUrl(commsTools, index);
    getPrevPageUrl(commsTools, index);
  }, [slug]);

  return (
    <section className="comms-tools-details">
      <BannerWithVideo videoPath="/videos/home-banner-video.mov" />
      <div className="container-fluid">
        <div className="comms-tools-details-inner">
          <h1 className="comms-detail-title">{pageDetail.name}</h1>
          <div className="row">
            <div className="col-md-6">
              <div dangerouslySetInnerHTML={{ __html: pageDetail.desc }} />
            </div>
            <div className="col-md-6">
              <MediaSection type={pageDetail.mediaType} media={pageDetail.media} />
            </div>
          </div>
          <Properties properties={pageDetail.props} />
        </div>
        <PageNavigation prevPageUrl={prevPageUrl} nextPageUrl={nextPageUrl} />
      </div>
    </section>
  );
}

export default CommsToolDetail;
