import React from 'react';
import "./contact-form.scss";

function ContactForm() {
    return (
        <form className='contact-form'>
            <div className='row'>
                <div className='col-6'>
                    <label>First Name</label>
                    <input type='text' className='form-control' />
                </div>
                <div className='col-6'>
                    <label>Surname</label>
                    <input type='text' className='form-control' />
                </div>
                <div className='col-6'>
                    <label>E-mail</label>
                    <input type='email' className='form-control' />
                </div>
                <div className='col-6'>
                    <label>Phone</label>
                    <input type='text' className='form-control' />
                </div>
                <div className='col-12'>
                    <label>Comments</label>
                    <textarea type='text' className='form-control' rows={5}></textarea>
                </div>
            </div>

            <button type='submit' className='btn-theme d-block ms-auto mt-5'>SUBMIT</button>
        </form>
    );
}

export default ContactForm;