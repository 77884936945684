import React from 'react';
import "./style.scss";

function WorldMap({ isGreyBg }) {

    return (
        <section id='worldMap' className={`world-map ${isGreyBg ? 'grey' : ''}`}>
            <img src={`/images/world-map/world-map${isGreyBg ? '-grey' : ''}.gif`} className='w-100' alt='Form Worldwide Locations' />
            <p className='text-center mt-5'>Campaigns delivered across four continents and 18 countries.</p>
        </section>
    );
}

export default WorldMap;