import React from 'react';
import { Link } from 'react-router-dom';
import "./style.scss";

function PageNavigation({ prevPageUrl, nextPageUrl }) {
    return (
        <div className='comms-tool-detail-navigation'>
            <Link to={prevPageUrl} className={`navigation-link ${prevPageUrl ? '' : 'disabled'}`}>
                <img src='/images/LeftArrow.svg' alt='Form Worldwide' />
                PREVIOUS
            </Link>

            <Link to={nextPageUrl} className={`navigation-link ${nextPageUrl ? '' : 'disabled'}`}>
                <img src='/images/RightArrow.svg' alt='Form Worldwide' />
                NEXT
            </Link>
        </div>
    );
}

export default PageNavigation;