import React from 'react';
import "./style.scss";

function AppHeadlineWithIcon({ imagePath, title }) {
    return (
        <div className='headline-with-icon'>
            <img src={imagePath} className='icon' alt='Form Worldwide' />
            <h1 className="title">{title}</h1>
        </div>
    );
}

export default AppHeadlineWithIcon;