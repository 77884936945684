import React, { useRef } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import Slider from "react-slick";
import CarouselItem from "./CarouselItem";

function Testimonials({ isGreyBg }) {
  const sliderRef = useRef();

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    duration: 4000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className={`testimonials-section ${isGreyBg ? "grey" : ""}`}>
      <div className="container">
        <div className="slider-wrapper">
          <Slider {...settings} ref={sliderRef}>
            <CarouselItem brandLogo="/images/testimonials/client-pg-logo.png">
              <p> Form Worldwide is one of the best agencies I have had the chance to work with. What sets them apart from others is their capability to anticipate challenges and pro-actively propose solutions ahead of time. I rely on them as if they were part of my team. The level of trust we have today helps me to focus on other challenges.</p>
              <span className="author">
                Cedric Le Perff
                <br />
                Global Design BM P&G Prestige Fragrances
              </span>
            </CarouselItem>
            <CarouselItem brandLogo="/images/testimonials/client-pg-logo.png">
              <p> Form Worldwide is one of the best agencies I have had the chance to work with. What sets them apart from others is their capability to anticipate challenges and pro-actively propose solutions ahead of time. I rely on them as if they were part of my team. The level of trust we have today helps me to focus on other challenges.</p>
              <span className="author">
                Cedric Le Perff
                <br />
                Global Design BM P&G Prestige Fragrances
              </span>
            </CarouselItem>
          </Slider>

          {/* ARROWS
                    ================================================================================================== */}
          <span
            role="button"
            className="arrow-left"
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
          >
            <img src="/images/chevron-arrow-left.svg" alt="Form Worldwide" />
          </span>
          <span
            role="button"
            className="arrow-right"
            onClick={() => {
              sliderRef.current.slickNext();
            }}
          >
            <img src="/images/chevron-arrow-right.svg" alt="Form Worldwide" />
          </span>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
