import React from 'react';
import { Link } from 'react-router-dom';
import "./style.scss";

function ProjectNavigation({ prevProjectUrl, nextProjectUrl }) {
    return (
        <div className='project-detail-navigation'>
            <Link to={prevProjectUrl} className={`navigation-link ${prevProjectUrl ? '' : 'disabled'}`}>
                <img src='/images/LeftArrow.svg' alt='Form Worldwide' />
                <span>PREVIOUS PROJECT</span>
            </Link>

            <Link to={nextProjectUrl} className={`navigation-link ${nextProjectUrl ? '' : 'disabled'}`}>
                <span className='order-2 order-md-1'>NEXT PROJECT</span>
                <img src='/images/RightArrow.svg' className='order-1 order-md-2' alt='Form Worldwide' />
            </Link>
        </div>
    );
}

export default ProjectNavigation;