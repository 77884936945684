import React from "react";
import "./style.scss";

function ClientLogos(props) {
  return (
    <div className="client-logos">
      <div className="item">
        <img src="/images/client-logos/DolceGabbana.svg" alt="Dolce Gabbana" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Unilever.svg" alt="Unilever" />
      </div>
      <div className="item">
        <img src="/images/client-logos/EsteeLauder.svg" alt="Estee Lauder" />
      </div>
      <div className="item">
        <img src="/images/client-logos/TetraPak.svg" alt="Tetra Pak" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Pernod_Ricard.svg" alt="Pernod Ricard" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Clinique.svg" alt="Clinique" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Thorntons.svg" alt="Thorntons" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Toni_Guy.svg" alt="Toni&Guy" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Procter_Gamble.svg" alt="Procter Gamble P&G" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Swarovski.svg" alt="Swarovski" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Heineken.svg" alt="Heineken" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Greenalls.svg" alt="Greenalls" />
      </div>
      <div className="item">
        <img src="/images/client-logos/IFF.svg" alt="IFF" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Berkeley_Square.svg" alt="Berkeley Square" />
      </div>
      <div className="item">
        <img src="/images/client-logos/Boss.svg" alt="Hugo Boss" />
      </div>
    </div>
  );
}

export default ClientLogos;
