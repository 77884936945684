import React from "react";
import "./styles.scss";
import dataHelpers from "../../../helpers/dataHelpers";
import { Link } from "react-router-dom";

function MenuWithIcons() {
  return (
    <section className="menu-with-icons">
      <div className="container">
        <div className="row justify-content-center justify-content-md-start">
          {dataHelpers.menuItems
            .filter((p) => p.name !== "Home")
            .map((item, index) => (
              <div className={`${index === 0 ? "col-12" : "col-6"} col-md`} key={item.id}>
                <Link to={item.url} className="menu-item">
                  <img src={`/images/icons/${item.image}`} className="menu-icon" alt={item.name} />
                  <div className="label">{item.name}</div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default MenuWithIcons;
