
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/app.scss';


import 'bootstrap/dist/js/bootstrap.bundle';

import MainLayout from './layouts/MainLayout';

function App() {
  return (
    <div className="App">
      <MainLayout />
    </div>
  );
}

export default App;
