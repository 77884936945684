import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import ScrollToTop from '../components/common/ScrollToTop';

import AppHeader from "../components/layout/AppHeader/"
import Home from '../pages/Home';
import AppFooter from '../components/layout/AppFooter';
import AboutUs from '../pages/AboutUs';
import WhatWeDo from '../pages/WhatWeDo';
import ProjectDetails from '../pages/ProjectDetails/';
import Services from '../pages/Services';
import Clients from '../pages/Clients';
import GetInTouch from '../pages/GetInTouch/';
import CommsToolDetail from '../pages/CommsToolDetails/index';


function MainLayout(props) {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <AppHeader />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/what-we-do" element={<WhatWeDo />} />
                <Route path="/projects/:slug" element={<ProjectDetails />} />
                <Route path="/services" element={<Services />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/get-in-touch" element={<GetInTouch />} />
                <Route path="/comms-tools/:slug" element={<CommsToolDetail />} />
            </Routes>
            <AppFooter />
        </BrowserRouter>
    );
}

export default MainLayout;