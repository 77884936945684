import React from "react";
import BannerWithVideo from "../../components/common/Banners/BannerWithVideo";
import AppHeadlineWithIcon from "../../components/layout/AppHeadlineWithIcon/index";
import Spacer from "../../components/common/Spacer";
import ContactForm from "../../components/get-in-touch/ContactForm";
import DeviceViews from "../../components/common/DeviceViews";

import "./style.scss";

function GetInTouch() {
  return (
    <section className="get-in-touch-page">
      <BannerWithVideo videoPath={"/videos/get-in-touch.mov"} />
      <AppHeadlineWithIcon imagePath={"/images/icons/get-in-touch.svg"} title="GET IN TOUCH" />
      <Spacer />
      <div className="get-in-touch-page-inner">
        <div className="row gx-5">
          <div className="col-md-4">
            <div className="address-section">
              <img src="/images/Phone_booth_shadow.png" className="contact-image" alt="Form Worldwide Get In Touch" />
              <p className="text-center">
                71-75 Shelton
                <br />
                Street Covent Garden
                <br />
                London WC2H 9JQ
                <br />
                <br />
                <a href="mailto:info@formworldwide.com">info@formworldwide.com</a>
                <br />
                <a href="mailto:nick.ward@formworldwide.com">nick.ward@formworldwide.com</a>
              </p>
            </div>
          </div>
          <div className="col-md-7 justify-self-end">
            <div className="form-area">
              <p>Whether you’d like to chat about business opportunities or are interested in joining our team, do drop us a line if you’d like to chat more.</p>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <DeviceViews />
    </section>
  );
}

export default GetInTouch;
