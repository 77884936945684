import React from 'react';
import "./style.scss";

function DigitallyMarketingRemastered({ isGreyBg }) {
    return (
        <div className={`marketing-digitally-remastered ${isGreyBg ? 'grey' : ''}`}>
            <section className='container'>
                <div className='page-heading' >
                    <img src='/images/logos/tagline.svg' className='tag-line' alt='Digitally Marketing Remastered' />
                </div>
                <p>We're a global marketing communications agency with a digital edge.
                    Where invention isn't constrained by communication channel, category, discipline or location.
                    We create experiences people can't ignore and help people to see your brand in a new light.</p>
            </section>
        </div>
    );
}

export default DigitallyMarketingRemastered;