import React from 'react';
import "./style.scss";


function MediaSection({ type, media }) {

    const renderImage = (imageName) => {
        return <img src={`/images/comms-tools/${imageName}`} className='comms-detail-media' alt='Form Worldwide' />
    }

    const renderVideo = (videoName) => {
        return (
            <video autoPlay loop onCanPlay={(e) => { try { e.target.muted = true;  e.target.play() } catch { }}} {... { playsinline: "", muted: "true" }} className='comms-detail-media'>
                <source src={`/videos/comms-tools/${videoName}`} type="video/mp4" />
            </video>
        )
    }

    return (
        <div className='comms-tool-detail-medias'>
            {(() => {
                switch (type) {
                    case "image":
                        return renderImage(media);
                    case "video":
                        return renderVideo(media);
                    default:
                        return <></>
                }
            })()}

        </div>
    );
}

export default MediaSection;