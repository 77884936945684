import React, { useEffect, useState } from "react";
import "./style.scss";

function CredsVideo() {
  const [showVideo, setShowVideo] = useState(false);
  const [muted, setMuted] = useState(true);

  const toggleMute = () => {
    setMuted(!muted);
    var video = document.getElementById("credsVideo");
    video.muted = !video.muted;
  };

  useEffect(() => {
    setTimeout(() => {
      setShowVideo(true);
    }, 500);
  });
  return (
    <>
      {showVideo && (
        <section className="creds-video">
          <video id="credsVideo" autoPlay loop muted v-if="showVideo" onCanPlay={(e) => { try { e.target.muted = true;  e.target.play() } catch { }}} {... { playsinline: "", muted: "true" }}>
            <source src="/videos/creds.mp4" type="video/mp4" />
          </video>
          <button className="mute-button" onClick={toggleMute}>
            {muted && <img src="/images/icons/unmute.svg" alt="Form Worldwide" />}
            {!muted && <img src="/images/icons/mute.svg" alt="Form Worldwide" />}
          </button>
        </section>
      )}
    </>
  );
}

export default CredsVideo;
