import React from "react";
import "./style.scss";
import QRItem from "./QRItem";

function ARApps({ data, showTitle = false }) {
  return (
    <section className="ar-apps">
      <div className="container">
        {showTitle && <h2 className="comp-heading">Take a Look</h2>}
        {data.map((item, index) => (
          <QRItem imagePath={item.image} qrImagePath={item.qr} label={item.label} name={item.name} url={item.url} key={index} />
        ))}
      </div>
    </section>
  );
}

export default ARApps;
