import React from 'react';
import "./service-list.scss";

function ServiceList(props) {
    return (
        <div className='service-list'>
            <div className='container-fluid'>
                <div className='row gx-5'>
                    <div className='col-md-4'>
                        <div className='item'>
                            <img src='/images/services/Consumer_Marketing.png' className='service-item-image' alt='Consumer Marketing' />
                            <div className='info'>
                                <h2 className='name'>Consumer Marketing</h2>
                                <div className='service-item-spacer' />
                                <p className='desc'>
                                    We create experiences people can’t ignore and help people to see your brand in a new light.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='item'>
                            <img src='/images/services/Trade_Marketing.png' className='service-item-image' alt='Trade Marketing' />
                            <div className='info'>
                                <h2 className='name'>Trade Marketing</h2>
                                <div className='service-item-spacer' />
                                <p className='desc'>
                                    A world-class team of specialists, dedicated to the successful creation, implementation, measurement and support of each campaign.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='item'>
                            <img src='/images/services/Employer_Comms.png' className='service-item-image' alt='Employer Comms' />
                            <div className='info'>
                                <h2 className='name'>Employer Comms</h2>
                                <div className='service-item-spacer' />
                                <p className='desc'>
                                    Creativity and invention is nothing without results. Bold concepts need rigour, under-pinned by data, measurements and detailed analytics is crucial.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ServiceList;