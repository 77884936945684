import React from 'react';

function QRItem({ imagePath, qrImagePath, label, name, url }) {
    return (
        <div className='item'>
            <div className='row align-items-center'>
                <div className='col-md-3'>
                    <img src={imagePath} className='w-100' alt={name} />
                </div>
                <div className='col-md-3 d-none d-sm-block'>
                    <div className='qr-wrapper'>
                        <img src={qrImagePath} className='w-100' alt={name} />
                    </div>
                </div>
                <div className='col-md-6'>
                    <h2 className='label'>
                        {label}
                    </h2>
                    <span className='name'>{name}</span>
                    <br />
                    <a href={url} className='link' target='_blank' rel="noreferrer">{url}</a>
                </div>
            </div>
        </div>
    );
}

export default QRItem;