import React from "react";
import "./style.scss";

function ProjectHeadline({ title, subTitle }) {
  return (
    <div className="project-headline">
      <h1 className="title">{title}</h1>
      <h2 className="sub-title" dangerouslySetInnerHTML={{ __html: subTitle }} />
    </div>
  );
}

export default ProjectHeadline;
