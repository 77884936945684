import React from 'react';
import "./style.scss";
import { NavLink } from 'react-router-dom';
import dataHelpers from '../../../helpers/dataHelpers';

function MenuDropdown({ classes }) {
    return (
        <div className={`dropdown dropdown-main-menu ${classes}`}>
            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                ☰
            </button>
            <ul className="dropdown-menu">
                <div className='text-end pe-3'>
                    <span className='close-button'>✖</span>
                </div>
                {
                    dataHelpers.menuItems.map(item =>
                        <li key={item.id}>
                            <NavLink className="dropdown-item" to={item.url}>
                                <div className='icon'>
                                    <img src={`/images/icons/${item.image}`} alt={item.name} />
                                </div>
                                <span className='text-uppercase'>{item.name}</span>
                            </NavLink>
                        </li>
                    )
                }
            </ul>
        </div>
    );
}

export default MenuDropdown;