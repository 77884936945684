import React from 'react';
import "./style.scss";
import { Link } from 'react-router-dom';
import dataHelpers from '../../../helpers/dataHelpers';

function CommsTools({ isGreyBg }) {
    return (
        <section className={`comms-tools ${isGreyBg ? 'grey' : ''}`}>
            <h2 className='section-title'>COMMS TOOLS</h2>
            <div className='row justify-content-center justify-content-md-start gx-5'>

                {
                    dataHelpers.commsTools.map((item, index) => (
                        <div className='col-6 col-md' key={index}>
                            <Link to={item.url} className='item'>
                                <img src={`/images/comms-tools/${item.coverImage}`} className='w-100' alt={item.name} />
                                <h3 className='label'>{item.name}</h3>
                            </Link>
                        </div>
                    ))
                }

            </div>
        </section>
    );
}

export default CommsTools;