import React from 'react';

function AboutText() {
    return (
        <div className='text-center'>
            <strong className='d-block mb-5'>Something unique happens when you take people on an unexpected journey.</strong>
            <p>It can inspire us to think and act differently, even just for a moment.
                In that instant we’re a little more receptive to new experiences, new conversations and new perspectives.
                And it’s in these moments that we look to connect with the help of digital.
                It’s brand communications re-imagined and marketing digitally remastered.</p>
        </div>
    );
}

export default AboutText;