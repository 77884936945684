import React from "react";
import "./style.scss";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import dataHelpers from "../../../helpers/dataHelpers";
import FooterSocialMedia from "./FooterSocialMedia";

function AppFooter() {
  return (
    <footer className="app-footer">
      <div className="app-footer-inner">
        <div className="row alin-align-items-stretch">
          <div className="col-6 col-lg-7 col-xl-8">
            <div className="footer-left">
              <div className="logos">
                <img src="/images/logos/logo-white.svg" className="footer-logo" alt="Form Worldwide" />
                <img src="/images/logos/tagline-white.svg" className="footer-headline-logo" alt="Form Worldwide" />
              </div>
              <div className="copyright">
                Form Worldwide Ltd. All rights reserved. © 2023
                <br />
                <br />
                <a href="/privacy-policy.html" target="_blank">PRIVACY POLICY</a>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-5 col-xl-4">
            <div className="row">
              <div className="col-md-6">
                <strong className="footer-headline">SITE MAP</strong>
                <ul className="nav footer-menu flex-column">
                  {dataHelpers.menuItems
                    .filter((p) => p.url !== "/get-in-touch")
                    .map((item) => (
                      <li className="nav-item" key={item.id}>
                        <NavLink to={item.url} className="nav-link">
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="col-md-6">
                <div className="footer-contact">
                  <strong className="footer-headline git">GET IN TOUCH</strong>
                  <div className="email-addresses">
                    <a href="mailto:info@formworldwide.com" rel="no-referrer">
                      info@formworldwide.com
                    </a>
                    <br />
                    <a href="mailto:nick.ward@formworldwide.com" rel="no-referrer">
                      nick.ward@formworldwide.com
                    </a>
                  </div>

                  <HashLink className="footer-contact-button" smooth to={"/get-in-touch#top"}>
                    CONTACT US
                  </HashLink>

                  <FooterSocialMedia />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default AppFooter;
