import React from 'react';
import BannerWithVideo from '../components/common/Banners/BannerWithVideo';
import AppHeadlineWithIcon from '../components/layout/AppHeadlineWithIcon';
import DescriptionArea from '../components/what-we-do/DescriptionArea';
import ProjectList from '../components/what-we-do/ProjectList';
import CommsTools from './../components/common/CommsTools/index';
import Spacer from './../components/common/Spacer';
import Testimonials from './../components/common/Testimonials/index';

function WhatWeDo(props) {
    return (
        <section className='what-we-do'>
            <BannerWithVideo videoPath="/videos/what-we-do.mov" />


            <div className='container'>
                <AppHeadlineWithIcon imagePath={'/images/icons/what-we-do.svg'} title="WHAT WE DO" />
                <DescriptionArea />
            </div>
            <ProjectList />
            <Spacer />
            <CommsTools isGreyBg={true} />
            <Testimonials />
        </section>
    );
}

export default WhatWeDo;