const dataHelpers = {
  menuItems: [
    { id: 1, name: "Home", image: "form.png", url: "/" },
    { id: 2, name: "About Us", image: "about-us.svg", url: "/about-us" },
    { id: 3, name: "What We Do", image: "what-we-do.svg", url: "/what-we-do" },
    { id: 4, name: "Services", image: "services.svg", url: "/services" },
    { id: 5, name: "Clients", image: "clients.svg", url: "/clients" },
    { id: 6, name: "Get In Touch", image: "get-in-touch.svg", url: "/get-in-touch" },
  ],

  caseStudies: {
    home: [
      { name: "HUGO BOSS SPORT CASE STUDY", image: "hugo-boss.jpg", url: "/projects/hugo-boss" },
      { name: "BLOOM GIN CASE STUDY", image: "bloom-gin.jpg", url: "/projects/bloom-london-dry-gin" },
      { name: "OPI NATURE STRONG & TRAVEL RETAIL CASE STUDY", image: "opi-nature.jpg", url: "/projects/opi-nature" },
      { name: "TOUSSAINT CASE STUDY", image: "toussaint.jpg", url: "/projects/brands-and-packaging" },
    ],
    aboutUs: [
      { name: "OPI CASE STUDY", image: "opi-nature.jpg", url: "/projects/opi-nature" },
      { name: "RETREAT AR APP CASE STUDY", image: "retreat-ar.png", url: "/projects/health-and-nature-wellness-retreat-app" },
      { name: "HUGO BOSS SPORT CASE STUDY", image: "hugo-boss.jpg", url: "/projects/hugo-boss" },
      { name: "BLOOM GIN CASE STUDY", image: "bloom-gin.jpg", url: "/projects/bloom-london-dry-gin" },
    ],
    clients: [
      { name: "CLINIQUE CASE STUDY", image: "clinique-smart-serum.jpg", url: "/projects/clinique" },
      { name: "HUGO BOSS SPORT CASE STUDY", image: "Hugo_Boss_Case_Study-2.png", url: "/projects/hugo-boss" },
      { name: "OPI CASE STUDY", image: "OPI_Case_Study-2.png", url: "/projects/opi-in-store-app" },
      { name: "YUCOCO CASE STUDY", image: "Yucoco_Case_Study.png", url: "#" },
    ],
  },

  arApps: {
    home: [
      {
        image: "/images/ar-apps/Health_Wellness_AR_App.png",
        qr: "/images/ar-apps/qr-codes/ar-app-retreat.png",
        label: "HEALTH & WELLNESS RETREAT AR APP",
        name: "Virtual Wellness Retreat",
        url: "https://iffapps.com/ar-app-retreat",
      },
      {
        image: "/images/ar-apps/Plant_based_Protein_App.png",
        qr: "/images/ar-apps/qr-codes/ar-app-supro-tex.png",
        label: "PLANT-BASED PROTEIN AR APP",
        name: "Innovative Alternative Protein AR WebApp",
        url: "https://iffapps.com/ar-app-supro-tex/",
      },
    ],
    services: [
      {
        image: "/images/ar-apps/Byte_AR_App.png",
        qr: "/images/ar-apps/qr-codes/arr-app-burger.png",
        label: "BYTE AR APP",
        name: "Plant-based Product Design AR WebApps",
        url: "https://iffapps.com/ar-app-burger/",
      },
      {
        image: "/images/ar-apps/Health_Wellness_AR_App.png",
        qr: "/images/ar-apps/qr-codes/ar-app-retreat.png",
        label: "HEALTH & WELLNESS RETREAT AR APP",
        name: "Virtual Wellness Retreat",
        url: "https://iffapps.com/ar-app-retreat",
      },
      {
        image: "/images/ar-apps/IBIE_Cucpake_AR_App.png",
        qr: "/images/ar-apps/qr-codes/arr-app-ibie.png",
        label: "IBIE CUPCAKE AR APP",
        name: "Build Your Own Cupcake AR WebApp",
        url: "https://iffapps.com/ar-app-ibie-cupcakes/index.html",
      },
      {
        image: "/images/ar-apps/Plant_based_Protein_App.png",
        qr: "/images/ar-apps/qr-codes/ar-app-supro-tex.png",
        label: "PLANT-BASED PROTEIN AR APP",
        name: "Innovative Alternative Protein AR WebApp",
        url: "https://iffapps.com/ar-app-supro-tex/",
      },
    ],
  },

  commsTools: [
    {
      coverImage: "InteractiveVideo_Circle.png",
      media: "Interactive_Video.png",
      mediaType: "image",
      name: "Interactive Video",
      url: "/comms-tools/interactive-video",
      desc: "NEXT-LEVEL VIDEO, where viewers can choose which content they watch and how they interact or navigate through the flow, of each video.<br/><br/> A fully customizable, completely interactive video experience, no longer the one-size-fits-all passive content of traditional videos.",
      props: [
        { icon: "eye.svg", desc: "<strong>+700%</strong> increase in view rates and engagement" },
        { icon: "noun-video-player.svg", desc: "<strong>+80%</strong> increase in video completion rates" },
        { icon: "memory.svg", desc: "<strong>+60%</strong> higher memorability" },
      ],
    },
    {
      coverImage: "WebAR_Circle.png",
      media: "Ar_Apps-for_Website.mp4",
      mediaType: "video",
      name: "Web AR",
      url: "/comms-tools/web-ar",
      desc: "UNIVERSALLY ACCESSIBLE, HIGH QUALITY WEB AR with no need to download an app. <br/><br/>Bring your brand and marketing to life with AR. Compatible across all devices and browsers for a completely frictionless interactive experience across mobile, tablet and desktop browsers.",
      props: [
        { icon: "touch.svg", desc: "AR experiences have <strong>2.4x</strong> higher interaction rates than <br/>traditional mobile apps" },
        { icon: "augmented-reality.svg", desc: "Brands using AR report up to a <strong>40%</strong> increase in conversion rates" },
        { icon: "increase.svg", desc: "Interacting with products that have AR experiences leads to a <strong>94%</strong> higher conversation" },
      ],
    },
    {
      coverImage: "AI-Content_Circle.png",
      media: "AI_Translate.png",
      mediaType: "image",
      name: "AI Language & Content",
      url: "/comms-tools/ai-language-content",
      desc: "AI Language & Content allows for a wealth of powerful, high-quality content generation, delivered cost-effectively and at speed, compared with other traditional methods.<br/><br/> AI language and content tools cover AI voice, listening, written content, translation, avatars, metahumans and a wealth of other tactical tools and capabilities.",
      props: [
        { icon: "clock.svg", desc: "Save up to <strong>90%</strong> of manual content creation time" },
        { icon: "increase.svg", desc: "Companies using AI content personalization techniques experience an average increase of <strong>19%</strong> in sales" },
        { icon: "sales-growth.svg", desc: "Content personalization powered by AI has been shown to increase website conversion rates by <strong>68%</strong>" },
      ],
    },
    {
      coverImage: "Gamification_Circle.png",
      media: "Gamification_Video_for_Website.mp4",
      mediaType: "video",
      name: "Gamification",
      url: "/comms-tools/gamification",
      desc: "Web-based game-creation platform allows for high quality, fully customizable, global or market specific games that put your customers in the driving seat.<br/><br/> Integrated into existing websites or mobile web-apps. Easy access - social media to QR code for frictionaless access everywhere.",
      props: [
        { icon: "engagement.svg", desc: "<strong>100-150%</strong> increase in engagement levels" },
        { icon: "gamification.svg", desc: "<strong>90%</strong> of employees being more productive when tasks are gamified" },
        { icon: "sales-growth.svg", desc: "An average increase of <strong>30%</strong> in conversion rates" },
      ],
    },
    {
      coverImage: "HybridEvents_Circle.png",
      media: "Hybrid_Events.png",
      mediaType: "image",
      name: "Hybrid Events",
      url: "/comms-tools/hybrid-events",
      desc: "Creating and delivering cutting edge global hybrid events, webcasts or virtual spaces, such as pop up stores or exhibitions. <br/><br/>Universally accessible and incorporating e-commerce, AR and a wealth of other cutting edge features.",
      props: [
        { icon: "Moneybag.svg", desc: "Cost savings, reducing expenses by <strong>30-50%</strong>" },
        { icon: "virtual-audience.svg", desc: "Have the potential to reach a wider audience, with virtual attendees making up <strong>30-70%</strong> of the total audience" },
        { icon: "satisfaction.svg", desc: "Hybrid events have demonstrated a <strong>62%</strong> increase in sponsor and exhibitor satisfaction" },
      ],
    },
  ],

  projects: [
    {
      name: "OPI NATURE STRONG & TRAVEL RETAIL",
      shortDescription: "Retail Toolkit",
      description: "Creation of retail ‘toolkit’ for NATURE STRONG concept with a modular design, offering a kit of parts, to help retailers and Wella grow retail presence across a range of store types and formats, including Travel Retail.",
      url: "/projects/opi-nature",
      coverImage: "opi-nature.jpg",
      bannerImage: "OPI_NS_Header.png",
      media: [
        { type: "image", image: "OPI-NS-Image-1.jpg" },
        { type: "image", image: "OPI-NS-Image-2.jpg" },
      ],
    },
    {
      name: "HEALTH & WELLNESS RETREAT AR APP",
      shortDescription: "Virtual Wellness Retreat",
      description: "Helping IFF customers to “re-imagine health & wellness” and to understand how IFF products and services can help, we developed a global mobile-first web-app utilizing a wealth of digital tools (360 scenes, AR etc..) to bring a virtual “health & wellness” retreat to life.",
      url: "/projects/health-and-nature-wellness-retreat-app",
      coverImage: "retreat-ar.png",
      bannerImage: "Health_Wellness_App_Header.png",
      media: [
        { type: "image", image: "Health-Wellness-Image-1.jpg" },
        { type: "image", image: "Health-Wellness-Image-2.jpg" },
      ],
    },
    {
      name: "PRODUCT DESIGN APPS",
      shortDescription: "SUPRO<sup>®</sup> TEX, Byte Bar, Burger & Nugget",
      description: "Challenged to help IFF teams engage with their customers around innovative new “plant-based” products. We developed a range of global “product design” AR WebApps that enable customers to digitally build their own personalized meal solutions and understand more about product benefits along the way.",
      url: "/projects/product-design-apps",
      coverImage: "product-design.png",
      bannerImage: "ProductDesignHeader.png",
      media: [
        { type: "image", image: "Product-Design-1.jpg" },
        { type: "image", image: "Product-Design-2.jpg" },
      ],
    },
    {
      name: "EVENTS & EXPERIENTIAL",
      shortDescription: "Cutting Edge Digital Experiences",
      description:
        "It’s a big deal when you host any event or take part in exhibiting your wares to a global audience. So we wanted to “step-it-up” for our clients whenever they put themselves out there.<br/><br/> With a wealth of cutting edge digital tools and technologies in our armoury, our clients are always able to get the lion’s share of everyone’s attention at any event they go to. Immersive, interactive mobile-first digital experiences and content that go hand-in-hand with the physical to get participants exploring, talking and buying, is the name of our game!",
      url: "/projects/events-and-experiental",
      coverImage: "events-experiential.png",
      bannerImage: "Events_Experiential_Header.png",
      media: [{ type: "image", image: "Events_Experiential-1.jpg" }],
    },
    {
      name: "OPI IN-STORE AR APP",
      shortDescription: "Store Design Using AR Technology",
      description: "When you’re about to spend your hard-earned cash on some furniture for your store, ideally the catalogue you’re shown needs to be good, right? Well, what if that catalogue could also show how it looks in your shop, open up some draws, turn the lighting on and even fast-track one for delivery? And all at the touch of a button? That’s way better right?",
      url: "/projects/opi-in-store-app",
      coverImage: "opi-in-store.png",
      bannerImage: "OPI_In_Store_Header.png",
      media: [{ type: "video", video: "COTY-OPI-Furniture-Video.mp4" }],
    },
    {
      name: "CG & MOTION GRAPHICS",
      shortDescription: "CG Modelling, Animation, & Motion Graphics Creation",
      description: "The full range of CG design, modelling, animation music and sfx in-house.<br/><br/> Whether it’s jaw-dropping ultra-high definition, photo-real work or re-topologized models for use in lower poly-count, mobile-based apps or web-AR, we have the capability to deliver world-class work, creatively and technically.",
      url: "/projects/cg-and-motion-graphics",
      coverImage: "cg-motion-graphics.png",
      bannerImage: "CG_Motion_Graphics_Header.png",
      media: [
        { type: "video", video: "CG_and_Motion_Graphics.mp4" },
        { type: "video", video: "COTY-OPI-Furniture-Video.mp4" },
        // { type: "video", video: "CG_Nugget_Sales_Kit_AR_Experience.mp4" },
      ],
    },
    {
      name: "BUSINESSES & BRANDS",
      shortDescription: "Form Worldwide, Legacy Design Labs, Yucoco",
      description:
        "Bringing a unique strategic and creative perspective to the table we’ve created award-winning brands and businesses that have made us and our clients proud.<br/><br/> Sometimes it’s the smallest comment that unlocks the most incredible insight. Putting a name and a creative face to any business or brand is a huge challenge and a priviledge, so those genuine nuggets of gold are the secret ingredients you need every time something special is born!",
      url: "/projects/businesses-and-brands",
      coverImage: "business-brands.png",
      bannerImage: "Businesses_Brands_Header.png",
      media: [
        { type: "image", image: "Businesses_Brands-1.jpg" },
        { type: "image", image: "Businesses_Brands-2.jpg" },
      ],
    },
    {
      name: "BRANDS & PACKAGING",
      shortDescription: "Toussaint and More",
      description:
        "The creative flair and technical expertise to name, design and produce award-winning brands and packaging solutions across a range of categories ensure that nothing stays on the shelf too long!<br/><br/> We’ve got an established track record in the creation of beautifully crafted, immediately memorable and successfully enticing brand and packaging solutions, from initial concepts, through to design development and all the way to launch. Not to mention our seamless activation of course!",
      url: "/projects/brands-and-packaging",
      coverImage: "toussaint.jpg",
      bannerImage: "Brands_Packaging_Header.png",
      media: [
        { type: "image", image: "Brands_Packaging-1.jpg" },
        { type: "image", image: "Brands_Packaging-2.jpg" },
      ],
    },

    {
      name: "GAMIFICATION",
      url: "/projects/gamification",
      shortDescription: "Interactive, Branded Game Experiences",
      description:
        "A suite of classically addictive, immensely playable interactive game experiences.<br/><br/> The secret to any great game is simplicity, creativity and challenge. Bringing all of our award-winning knowledge and experience to bear we were delighted to hear and see the results. A series of deliciously addictive games, anyone could pick up and play instantly and boy, did they get played. Twenty-two markets globally, across desktop, tablet and phone, we covered all the bases and certainly raised a few smiles in the process.",
      coverImage: "gamification.png",
      bannerImage: "Gamification_Header.png",
      media: [
        { type: "image", image: "Gamification-1.jpg" },
        { type: "video", video: "Gamification.mp4" },
      ],
    },
    {
      name: "GREENALL’S LONDON DRY GIN",
      shortDescription: "Consumer Brand Campaign",
      description:
        "Greenall’s needed to secure stronger retail presence across UK and International markets so we positioned Greenall’s as being the official sponsor of ‘Great British Moments’. This enabled the brand to take ownership of all things British – notably sponsorship of the 2013 Ashes tour in the UK, in-store promotions to ‘win a mini’, even down to delivering a raft of very ‘British’ merchandise such as deck chairs and umbrellas. All this delivered a distinctive and highly effective platform to drive sampling, trial, awareness & ultimately encouraging purchase.",
      url: "/projects/greenalls-london-gin-dry",
      coverImage: "greenalls-gin.jpg",
      bannerImage: "Greenalls_Header.png",
      media: [
        { type: "image", image: "Greenalls-1.jpg" },
        { type: "vimeo", url: "https://player.vimeo.com/video/257739704?h=acead760bc" },
      ],
    },
    {
      name: "HUGO BOSS",
      shortDescription: "In-Store AR Experience & Retail Display",
      description:
        "HUGO BOSS launched ‘Limited Edition BOSS BLACK Soccer’, a new masculine fragrance, and wanted to underline the brand’s association with top-level professional football. We set up a number of interactive photo-capture booths across Europe, which processed the shopper’s image using AR and pre-filmed video footage, to portray a personal ‘meeting’ with a football hero. The booths also had connectivity to social media and the user’s mobile. The activity was rolled out in 19 markets globally across both domestic and travel retail outlets.",
      url: "/projects/hugo-boss",
      coverImage: "hugo-boss.jpg",
      bannerImage: "Hugo_Boss_Sport_Header.png",
      media: [
        { type: "vimeo", url: "https://player.vimeo.com/video/257877687?h=4838934443" },
        { type: "image", image: "Hugo-Boss-Sport-1.jpg" },
        { type: "image", image: "Hugo-Boss-Sport-2.jpg" },
        { type: "image", image: "Hugo-Boss-Sport-3.jpg" },
      ],
    },
    {
      name: "TONI&GUY",
      shortDescription: "Trade Marketing & Sales Force Tool",
      description:
        "TONI&GUY were looking to bring their catalogue of ‘Glam Range’ T&G products to life and communicate new refreshed packaging concepts prior to launch. So we developed an AR app where customers could interact with the new ‘virtually real’ products, view videos that bring the science to life, plus learn about the looks that could be created using these new products. Featured in key trade events and used in vital trade marketing and sales-force activity this app helped secure vital trade support when no products physically existed.",
      url: "/projects/toni-and-guy-glam-range",
      coverImage: "toni-guy-trade-marketing.jpg",
      bannerImage: "T_G_Glam_header.png",
      media: [
        { type: "vimeo", url: "https://player.vimeo.com/video/257739668?h=9313c8c461" },
        { type: "image", image: "T_G_Glam-1.jpg" },
      ],
    },
    {
      name: "BLOOM LONDON DRY GIN",
      shortDescription: "Pop-Up ‘Pick Your Own’ Experiential Campaign",
      description:
        "To celebrate BLOOM’S distinctively floral brand character alongside driving awareness, trial & purchase, we created the BLOOM ‘Pick Your Own’ events and pop-ups. Including visual brand identity and collateral for a fully integrated PR and marketing campaign, which culminated in a nation-wide, experiential pop-up event. Consumers picked their own organic strawberries from an “English Country Garden” experience in-store, for use in the Bloom signature cocktails, made personally for them by top London mixologists.",
      url: "/projects/bloom-london-dry-gin",
      coverImage: "bloom-gin.jpg",
      bannerImage: "bloom-header.png",
      media: [
        { type: "image", image: "Bloom-1.jpg" },
        { type: "image", image: "Bloom-2.jpg" },
        { type: "image", image: "Bloom-3.jpg" },
      ],
    },
    {
      name: "TONI&GUY",
      shortDescription: "‘Bold&You’ Consumer Activation App",
      description:
        "Unilever were looking to grow the Toni&Guy brand in the US market and build brand equity and awareness in the Mexican market. We created an AR app, as part of their wider campaign, that’s available on the Apple and Google Play App Stores. The app enables users to trial different hairstyles and colours, learn how to recreate the looks at home and share their images to social media.",
      url: "/projects/tony-and-guy-bold-and-you",
      coverImage: "toni-guy-bold-you.jpg",
      bannerImage: "T_G_bold_you_header.png",
      media: [
        { type: "vimeo", url: "https://player.vimeo.com/video/258111446?h=9a190fc50c" },
        { type: "image", image: "T_G_Bold_You-1.jpg" },
      ],
    },
    {
      name: "CLINIQUE SMART SERUM",
      shortDescription: "Interactive Travel Retail Experience",
      description:
        "Clinique were looking to deliver a high impact, digital interaction, to support the launch of Clinique Smart Serum at Oslo and Heathrow T5 airports and reinforce Clinique’s position as skin science experts. We created a branded, interactive touch screen where consumers could touch ‘active’ molecules to reveal ‘Smart Facts’ about their skin and learn more about the Smart Serum derma-technology.",
      url: "/projects/clinique-smart-serum",
      coverImage: "clinique-smart-serum.jpg",
      bannerImage: "Clinique-Header5.png",
      media: [
        { type: "image", image: "Clinique-1.jpg" },
        { type: "image", image: "Clinique-2.jpg" },
      ],
    },
    {
      name: "SOCIAL MEDIA MARKETING",
      shortDescription: "Next Level Ideas to Boost Social Engagement",
      description: "Using the latest WebAR technology, we’re making a splash with inventive new ideas that take social media posts to the next level.<br/><br/> With the world of social firmly entrenched in the arsenal of brand marketing we are always looking for ways to disrupt this world and judging by the results I think we found something special.",
      url: "/projects/social-media-marketing",
      coverImage: "social-media-marketing.png",
      bannerImage: "Social_Media_Header.png",
      media: [
        { type: "image", image: "Social-Media-1.jpg" },
        { type: "video", video: "social-media-bar_video.mov" },
      ],
    },
    {
      name: "VIRTUAL POP-UPS",
      shortDescription: "Virtual Spaces Re-imagined",
      description: "Inventive virtual spaces re-imagined. From stores and pop-ups, to wellness retreats and Zen health experiences, it’s digital and e-commerce with a difference!<br/><br/> Breathtakingly rich, uniquely immersive spaces, tailor-made for shopping, exploring, or just hanging out! We’ve created a raft of mobile-first digital spaces that customers love and brands thrive in.",
      url: "/projects/virtual-pop-ups",
      coverImage: "virtual-pop-ups.png",
      bannerImage: "Virtual_Pop_up_Header.png",
      media: [
        { type: "image", image: "Virtual-Pop-up-1.jpg" },
        { type: "video", video: "Virtual_Space.mp4" },
      ],
    },
  ],
};

export default dataHelpers;
