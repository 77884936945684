import React, { useEffect, useRef } from 'react';
import "./style.scss";


import MenuDropdown from '../MenuDropdown/';
import { Link } from 'react-router-dom';

function AppHeader() {
    const headerRef = useRef();
    useEffect(() => {

        window.onscroll = () => {
            if (window.scrollY > 250)
                headerRef.current.classList.add("scrolled")
            else
                headerRef.current.classList.remove("scrolled")
        }
    })
    return (
        <header className='app-header ' ref={headerRef}>
            <div className='row'>
                <div className='col-6'>
                    <Link to="/">
                        <img src="/images/logos/logo-white.svg" className='app-header-logo' height="40" alt="Form" />
                    </Link>
                </div>
                <div className='col-6'>
                    <MenuDropdown classes={'ms-auto'} />
                </div>
            </div>
        </header>
    );
}

export default AppHeader;