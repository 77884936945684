import React from "react";
import AppHeadlineWithIcon from "../components/layout/AppHeadlineWithIcon/index";
import BannerWithVideo from "../components/common/Banners/BannerWithVideo";
import ServiceList from "../components/services/ServiceList";
import Spacer from "./../components/common/Spacer";
import ARApps from "../components/common/ARApps";
import CommsTools from "../components/common/CommsTools";
import Testimonials from "../components/common/Testimonials";
import dataHelpers from "../helpers/dataHelpers";

function Services() {
  return (
    <section className="services-section">
      <BannerWithVideo videoPath={"/videos/about-us.mp4"} />
      <div className="container">
        <AppHeadlineWithIcon imagePath={"/images/icons/services.svg"} title={"SERVICES"} />
        <p className="text-center">We think, create, produce, manage, measure and support a unique variety of inspiring brands, people and businesses across a wide range of channels, categories and disciplines.</p>
      </div>
      <Spacer />
      <ServiceList />
      <Spacer />
      <ARApps data={dataHelpers.arApps.services} showTitle={true} />
      <Spacer />
      <CommsTools isGreyBg={true} />
      <Testimonials />
    </section>
  );
}

export default Services;
