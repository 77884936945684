import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

function ProjectAbout({ description }) {
  return (
    <div className="project-detail-description">
      <p dangerouslySetInnerHTML={{ __html: description }} />
      <Link to="/what-we-do" className="back-button">
        Back to What We Do
      </Link>
    </div>
  );
}

export default ProjectAbout;
