import React from 'react';

function CarouselItem({ brandLogo, children }) {
    return (
        <div className='carousel-item'>
            <div className='carousel-item-inner'>
                {children}
            </div>
            <img src={brandLogo} className='client-logo' alt='Form Worldwide' />
        </div>


    );
}

export default CarouselItem;