import React from 'react';

function AboutUsGallery(props) {
    return (
        <div className='about-us-gallery'>
            <div className='row g-0'>
                {
                    [...Array(18)].map((x, i) => (
                        <div className='col-4 col-md-2' key={i}>
                            <img src={`/images/about-us/gallery/${i + 1}.png`} className='w-100' alt='Form Worldwide' />
                        </div>
                    ))
                }

            </div>
        </div>
    );
}

export default AboutUsGallery;