import React from 'react';
import AppHeadlineWithIcon from '../components/layout/AppHeadlineWithIcon';
import BannerWithVideo from '../components/common/Banners/BannerWithVideo';
import AboutUsGallery from '../components/about-us/AboutUsGallery';
import CredsVideo from './../components/common/CredsVideo/index';
import Spacer from '../components/common/Spacer';
import AboutText from '../components/about-us/AboutText';
import MarketingDigitallyRemastered from '../components/common/MarketingDigitallyRemastered';
import CommsTools from './../components/common/CommsTools/';
import Testimonials from '../components/common/Testimonials';
import CaseStudies from '../components/common/CaseStudies';
import dataHelpers from '../helpers/dataHelpers';

function AboutUs() {
    return (
        <section className='about-us'>
            <BannerWithVideo videoPath="/videos/about-us.mp4" />
            <div className='container'>
                <AppHeadlineWithIcon imagePath={'/images/icons/about-us.svg'} title={'About Us'} />
                <AboutText />
            </div>

            <Spacer />
            <AboutUsGallery />
            <CredsVideo />
            <MarketingDigitallyRemastered isGreyBg={true} />
            <CommsTools />
            <Testimonials isGreyBg={true} />
            <CaseStudies data={dataHelpers.caseStudies.aboutUs} />
        </section>
    );
}

export default AboutUs;