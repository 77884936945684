import React from 'react';

function DescriptionArea() {
    return (
        <p className='text-center py-5'>
            We often work closely with people who need fresh, inventive thinking and value creativity, commitment, hard work, collaboration and results.
            Working across many different countries and time-zones, challenged and inspired to keep developing inventive,
            award-winning work that delivers tangible, measurable results.
        </p>
    );
}

export default DescriptionArea;