import React from 'react';
import "./style.scss";

function BannerWithImage({ imagePath }) {
    return (
        <div className='banner'>
            <img src={imagePath} className="w-100" alt='' />
        </div>
    );
}

export default BannerWithImage;