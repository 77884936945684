import React from 'react';
import "./style.scss";


function MediaSection({ medias }) {

    const renderImage = (imageName, index) => {
        return <img src={`/images/projects/media/${imageName}`} className='w-100' key={index} alt='Form Worldwide' />
    }

    const renderVideo = (videoName, index) => {
        return (
            <video controls className='w-100' autoPlay onCanPlay={(e) => { try { e.target.muted = true;  e.target.play() } catch { }}} {... { playsinline: "", muted: "true" }}>
                <source src={`/videos/projects/${videoName}`} type="video/mp4" key={index} />
            </video>
        )
    }

    const renderVimeo = (url, index) => {
        return (
            <div className="responsive-iframe">
                <iframe src={url} key={index} width="640" height="360" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen title='Fowms WorldWide' />
            </div>
        )
    }


    return (
        <div className='project-details-medias'>
            {
                medias?.map((media, index) => {
                    switch (media.type) {
                        case "image":
                            return renderImage(media.image, index);
                        case "vimeo":
                            return renderVimeo(media.url, index);
                        case "video":
                            return renderVideo(media.video, index);
                        default:
                            return <></>
                    }
                })
            }
        </div>
    );
}

export default MediaSection;