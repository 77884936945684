import React from "react";
import dataHelpers from "../../helpers/dataHelpers";
import "./project-list.scss";
import { Link } from "react-router-dom";

function ProjectList() {
  return (
    <div className="what-we-do-projects">
      <div className="row gx-4 gy-5">
        {dataHelpers.projects.map((item, index) => (
          <div className="col-6 col-md-4" key={index}>
            <Link to={item.url} className="item">
              <img src={`/images/projects/covers/${item.coverImage}`} className="cover-img" alt={item.name} />
              <h2 className="name">{item.name}</h2>
              <span className="short-desc" dangerouslySetInnerHTML={{ __html: item.shortDescription }} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectList;
