import React from 'react';
import BannerWithVideo from './../components/common/Banners/BannerWithVideo';
import AppHeadlineWithIcon from '../components/layout/AppHeadlineWithIcon';
import ClientLogos from '../components/clients/ClientLogos';
import Testimonials from '../components/common/Testimonials';
import CaseStudies from '../components/common/CaseStudies';
import dataHelpers from '../helpers/dataHelpers';
import WorldMap from '../components/common/WorldMap';

function Clients() {
    return (
        <section className='clients-page' >
            <BannerWithVideo videoPath={'/videos/clients.mov'} />
            <AppHeadlineWithIcon imagePath={'/images/icons/clients.svg'} title={"CLIENTS"} />
            <ClientLogos />
            <Testimonials isGreyBg={true} />
            <CaseStudies data={dataHelpers.caseStudies.clients} />
            <WorldMap isGreyBg={true} />
        </section>
    );
}

export default Clients;