import React, { useState } from "react";
import "./style.scss";

function BannerWithVideo({ videoPath }) {
    const [videoLoading, setVideoLoading] = useState(true);

    const handleVideoLoaded = () => {
        setTimeout(() => {
            setVideoLoading(false);
        }, 200000);
    };

    return (
        <div className="banner">
            {videoLoading && (
                <div className="video-loading">
                    <div className="spinner-grow" role="status"></div>
                    Loading...
                </div>
            )}
            <video autoPlay loop onLoadedData={handleVideoLoaded} onCanPlay={(e) => { try { e.target.muted = true;  e.target.play() } catch { }}} {... { playsinline: "", muted: "true" }}>
                <source src={videoPath} type="video/mp4" />
            </video>
        </div>
    );
}

export default BannerWithVideo;
