import React, { Fragment } from 'react';
import MarketingDigitallyRemastered from '../components/common/MarketingDigitallyRemastered/';
import MenuWithIcons from '../components/home/MenuWithIcons/index.js';
import Spacer from '../components/common/Spacer.js';
import CredsVideo from '../components/common/CredsVideo/index.js';
import CaseStudies from './../components/common/CaseStudies/index';
import ARApps from '../components/common/ARApps/index.js';
import Clients from '../components/home/Clients/index.js';
import DeviceViews from '../components/common/DeviceViews/index.js';
import CommsTools from '../components/common/CommsTools/index.js';
import Testimonials from '../components/common/Testimonials/index.js';
import WorldMap from '../components/common/WorldMap/index.js';
import BannerWithVideo from '../components/common/Banners/BannerWithVideo.js';

import dataHelpers from '../helpers/dataHelpers.js';

function Home(props) {
    return (
        <Fragment>
            <BannerWithVideo videoPath="/videos/home-banner-video-an.mp4" />
            <Spacer />
            <MarketingDigitallyRemastered />
            <Spacer />
            <MenuWithIcons />
            <Spacer />
            <CredsVideo />
            <CaseStudies data={dataHelpers.caseStudies.home} isGreyBg={true} />
            <Spacer />
            <ARApps data={dataHelpers.arApps.home} />
            <CommsTools isGreyBg={true} />
            <Clients />
            <Testimonials isGreyBg={true} />
            <WorldMap />
            <DeviceViews isGreyBg={true} />
        </Fragment>
    );
}

export default Home;