import React from 'react';

function FooterSocialMedia(props) {
    return (
        <div className='footer-social-media'>
            <div className="row gx-3">
                <div className='col'>
                    <a href='/' target='_blank'>
                        <img src='/images/social-media/LinkedIn.svg' className="w-100" alt='Forms Linkedin' />
                    </a>
                </div>
                <div className='col'>
                    <a href='/' target='_blank'>
                        <img src='/images/social-media/Facebook.svg' className="w-100" alt='Forms Facebook' />
                    </a>
                </div>
                <div className='col'>
                    <a href='/' target='_blank'>
                        <img src='/images/social-media/Twitter.svg' className="w-100" alt='Forms Twitter' />
                    </a>
                </div>
                <div className='col'>
                    <a href='/' target='_blank'>
                        <img src='/images/social-media/Instagram.svg' className="w-100" alt='Forms Instagram' />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default FooterSocialMedia;