import React from 'react';
import "./style.scss";

function Properties({ properties }) {
    return (
        <div className='comms-tools-properties'>
            <div className='row'>
                {
                    properties?.map((item, index) => (
                        <div className='col-md-4' key={index}>
                            <div className='item'>
                                <img src={`/images/comms-tools/icons/${item.icon}`} className='icon' alt='Form Worldwide' />
                                <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                            </div>
                        </div>

                    ))
                }
            </div>
        </div>
    );
}

export default Properties;