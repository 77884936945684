import React from "react";
import "./style.scss";

function DeviceViews({ isGreyBg }) {
  return (
    <section className={`device-views-section ${isGreyBg ? "grey" : ""}`}>
      <div className="container">
        <div className="devices-inner">
          <img src="/images/device-screens-mockup.png" className="w-100" alt="Form Worldwide" />
          <div className="video-pc">
            <video src="/videos/home-banner-video.mov" autoPlay loop controls={false} onCanPlay={(e) => { try { e.target.muted = true;  e.target.play() } catch { }}} {... { playsinline: "", muted: "true" }} />
          </div>
          <div className="video-tablet">
            <video src="/videos/what-we-do.mov" autoPlay loop controls={false} onCanPlay={(e) => { try { e.target.muted = true;  e.target.play() } catch { }}} {... { playsinline: "", muted: "true" }} />
          </div>
          <div className="video-mobile">
            <video src="/videos/clients.mov" autoPlay loop controls={false} onCanPlay={(e) => { try { e.target.muted = true;  e.target.play() } catch { }}} {... { playsinline: "", muted: "true" }} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DeviceViews;
