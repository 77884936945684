import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

function Clients() {
  return (
    <section className="clients-section">
      <Link to={"/clients"}>
        <img src="/images/ClientsWallImageCrop.png" className="clients-img" alt="Clients" />
      </Link>
    </section>
  );
}

export default Clients;
